import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { useCareerTalkingPoints } from '../hooks/useCareerTalkingPoints';

import Helmet from 'react-helmet';
import SEO from '../components/seo';

import OGImage from '../images/og-careers.png';

import { notSmall } from '../styles/mediaQueryMixins';
import { TalkingPointsSection } from '../components/Careers/TalkingPoint';
import { PrimaryContent } from '../components/Careers/PrimaryContent';
import { CareersHeader } from '../components/Careers/CareersHeader';
import { CareersFooter } from '../components/Careers/CareersFooter';
import { OpenPositions } from '../components/Careers/OpenPositions';

const BodyStyle = createGlobalStyle`
  body {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }
  :root {
    --primary-body: #31250D;
    --internet-blue: #0C50FF;
    --dark-blue: #0047FF;
  }
  .slide-pane__overlay {
    z-index: 9999;
  }
`;

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3.125rem;
  width: 100%;
  max-width: 67.5rem;
  box-sizing: border-box;

  padding: 3.125rem 1.25rem;
  ${notSmall`
    padding: 3.125rem 2.5rem;
  `}
`;

const JobsPage = ({ location }) => {
  const talkingPoints = useCareerTalkingPoints();
 
  return (
    <Layout>
      <SEO
        title="The Browser Company | Careers"
        description="We're building a better way to use the internet with Arc and Dia. Come work with us."
      />
      <Helmet>
        <meta property="og:image" content={OGImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="643" />
        <html className="dark" />
      </Helmet>
      <BodyStyle />
        <CareersHeader />
        <Container>
          <PrimaryContent
            title="A better way to use the internet"
            body={(
              <>
                <p>The Browser Company of New York is a group of friendly humans working to make the internet feel more like home again. But how?</p>
                <p>The web browser is one of the most important tools we use — not just on our computers, but in our lives. The world has changed in the past 15 years, but our web browsers look and behave pretty much the same. We think it’s time to push the web browser forward again, which is why we built <a href="https://arc.net/" target='_blank'>Arc</a> and now <a href="https://www.diabrowser.com/" target="_blank">Dia</a> &mdash; browsers that aren't just faster, but also more personal, focused, creative… and maybe even more fun.</p>
              </>
            )}
          />
          <TalkingPointsSection points={talkingPoints} location={location} />
          <OpenPositions />
        </Container>
      <CareersFooter />
    </Layout>
  );
};

export default JobsPage;
